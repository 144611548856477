import { api } from "../core/api";

const createEscrow = async (data) => {
  const response = await api.post(
      'api/project/escrow/store',
      data
  );
  return response.data;
}

const getEscrow = async (project_id) => {
  const response = await api.get(
      `api/project/${project_id}/escrow/edit`
  );
  return response.data;
}

const updateEscrow = async (data) => {
  const response = await api.put(
      `api/project/escrow/${data.id}/update`,
      data
  );
  return response.data;
}

export {
  createEscrow, getEscrow, updateEscrow
};
