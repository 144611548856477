import { api } from "../core/api";

const getallBottomCabinets = async () => {
    const response = await api.get(`/api/project/type/get/All/bottom_cabinets`);
    return response.data;
}

const getAllDoors = async () => {
    const response = await api.get(`api/project/type/get/All/doors`);
    return response.data;
}

const getAllExteriorEndings = async () => {
    const response = await api.get(`api/project/type/get/All/exterior_endings`);
    return response.data;
}

const getAllExteriorPaints = async () => {
    const response = await api.get(`api/project/type/get/All/exterior_paints`);
    return response.data;
}

const getAllFloors = async () => {
    const response = await api.get(`api/project/type/get/All/floors`);
    return response.data;
}

const getAllInteriorEndings = async () => {
    const response = await api.get(`api/project/type/get/All/interior_endings`);
    return response.data;
}

const getAllInteriorPaints = async () => {
    const response = await api.get(`api/project/type/get/All/interior_paints`);
    return response.data;
}

const getAllIrons = async () => {
    const response = await api.get(`api/project/type/get/All/irons`);
    return response.data;
}

const getAllWindows = async () => {
    const response = await api.get(`api/project/type/get/All/windows`);
    return response.data;
}

const getAllUpstairsCabinets = async () => {
    const response = await api.get(`api/project/type/get/All/upstairs_cabinets`);
    return response.data;
}

const getAllTerrain = async () => {
    const response = await api.get(`api/project/type/get/All/terrain`);
    return response.data;
}

const getAllKitchens = async () => {
    const response = await api.get(`api/project/type/get/All/kitchens`);
    return response.data;
}

const getAllTypologies = async () => {
    const response = await api.get(`api/typology-types/all`);
    return response.data;
}

const getAllHousingTypes = async () => {
    const response = await api.get(`api/houses-types/all`);
    return response.data;
}

const getAllConstructionSystems = async () => {
    const response = await api.get(`api/construction-systems/all`);
    return response.data;
}

const getAllBanks = async () => {
    const response = await api.get(`api/project/type/get/All/banks`);
    return response.data;
}

const getAllFiduciary = async () => {
    const response = await api.get(`api/project/type/get/All/fiduciary`);
    return response.data;
}

export {
    getallBottomCabinets, 
    getAllDoors,
    getAllExteriorEndings,
    getAllExteriorPaints,
    getAllFloors,
    getAllInteriorEndings,
    getAllInteriorPaints,
    getAllIrons,
    getAllWindows,
    getAllUpstairsCabinets,
    getAllTerrain,
    getAllKitchens,
    getAllTypologies,
    getAllHousingTypes,
    getAllConstructionSystems,
    getAllBanks,
    getAllFiduciary,
};