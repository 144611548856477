<template>
  <div>
    <Tabs :activeTab="6" />
    <form @submit.prevent="handleSubmit">
      <div class="content-wrap-container">
        <div class="row">
          <div class="col-md-12">
            <h5 class="text-start mt-3 mb-3">Datos del Fideicomiso</h5>
            <div class="row mb-3">
              <div class="col-md-2 form-group">
                <label for="rnc">RNC del Fideicomiso</label>
                <input
                  type="text"
                  id="rnc"
                  class="form-control"
                  v-model="form.rnc_escrow"
                  v-mask="'#-##-#####-#'"
                  :readonly="isReadonly"
                  @change="getRNCInformation()"
                  :maxlength="20"
                  required
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-md-4 form-group">
                <label for="business_name">Nombre del Fideicomiso</label>
                <input
                  type="text"
                  id="business_name"
                  class="form-control"
                  v-model="form.name_escrow"
                  :readonly="true"
                  :maxlength="191"
                  required
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-md-3 form-group">
                <label for="bank_id">Banco de la Cuenta del Fideicomiso</label>
                <select
                  name=""
                  id="bank_id"
                  v-model="form.bank_id"
                  :disabled="isReadonly"
                  class="form-control"
                  required
                >
                  <option v-for="bank in banks" :key="bank.id" :value="bank.id">
                    {{ bank.name }}
                  </option>
                </select>
                <span class="error d-none"></span>
              </div>
              <div class="col-md-3 form-group">
                <label for="account_number_escrow">Número de Cuenta</label>
                <input
                  type="number"
                  id="account_number_escrow"
                  class="form-control"
                  v-model="form.account_number_escrow"
                  :readonly="isReadonly"
                  :maxlength="50"
                  required
                />
                <span class="error d-none"></span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm form-group">
                <label for="name_dev">Nombre Desarrollador / Constructor</label>
                <input
                  type="text"
                  id="name_dev"
                  class="form-control"
                  v-model="form.name_dev"
                  :readonly="isReadonly"
                  :maxlength="150"
                  required
                />
                <span class="error d-none"></span>
              </div>

              <div class="col-sm form-group">
                <label for="identification_number_dev"
                  >Cédula de Identidad / Pasaporte</label
                >
                <input
                  type="number"
                  id="identification_number_dev"
                  class="form-control"
                  v-model="form.identification_number_dev"
                  :readonly="isReadonly"
                  :maxlength="20"
                  required
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-sm form-group">
                <label for="phone_number_dev">Teléfono</label>
                <input
                  type="text"
                  v-mask="'(###) ### ####'"
                  id="phone_number_dev"
                  class="form-control"
                  v-model="form.phone_number_dev"
                  :readonly="isReadonly"
                  :maxlength="20"
                />
                <span class="error d-none"></span>
              </div>

              <div class="col-sm form-group">
                <label for="cellphone_number_dev">Celular</label>
                <input
                  id="cellphone_number_dev"
                  v-mask="'(###) ### ####'"
                  class="form-control"
                  v-model="form.cellphone_number_dev"
                  :readonly="isReadonly"
                  :maxlength="20"
                  required
                />
                <span class="error d-none"></span>
              </div>
              <div class="col-sm form-group">
                <label for="email_dev">Correo electrónico</label>
                <input
                  type="text"
                  id="email_dev"
                  class="form-control"
                  v-model="form.email_dev"
                  :readonly="isReadonly"
                  :maxlength="100"
                  required
                />
                <span class="error d-none"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mt-2">
          <div class="col-md-12">
            <h5 class="text-start mt-3 mb-3">Datos de la Fiduciaria</h5>
          </div>

          <div class="col-md-2 form-group">
            <label for="total_assets">Fiduciaria</label>
            <select
              name=""
              id="fiduciary_id"
              v-model="form.fiduciary_id"
              :disabled="isReadonly"
              class="form-control"
            >
              <option v-for="f in fiduciary" :key="f.id" :value="f.id">
                {{ f.name }}
              </option>
            </select>
            <span class="error d-none"></span>
          </div>
          <div class="col-md-2 form-group">
            <label for="rnc_fiduciary">RNC Fiduciaria</label>
            <input
              type="number"
              id="rnc_fiduciary"
              class="form-control"
              v-model="form.rnc_fiduciary"
              :readonly="isReadonly"
              :maxlength="20"
              required
            />
            <span class="error d-none"></span>
          </div>
          <div class="col-md-2 form-group">
            <label for="contact_fiduciary">Contacto Fiduciaria</label>
            <input
              type="text"
              id="contact_fiduciary"
              class="form-control"
              v-model="form.contact_fiduciary"
              :readonly="isReadonly"
              :maxlength="100"
              required
            />
            <span class="error d-none"></span>
          </div>
          <div class="col-md-2 form-group">
            <label for="phone_number_fiduciary">Teléfono</label>
            <input
              type="number"
              id="phone_number_fiduciary"
              class="form-control"
              v-model="form.phone_number_fiduciary"
              :readonly="isReadonly"
              :maxlength="20"
              required
            />
            <span class="error d-none"></span>
          </div>
          <div class="col-md-2 form-group">
            <label for="cellphone_number_fiduciary">Celular</label>
            <input
              type="number"
              id="cellphone_number_fiduciary"
              class="form-control"
              v-model="form.cellphone_number_fiduciary"
              :readonly="isReadonly"
              :maxlength="20"
            />
            <span class="error d-none"></span>
          </div>
          <div class="col-md-2 form-group">
            <label for="email_fiduciary">Correo electrónico</label>
            <input
              type="text"
              id="email_fiduciary"
              class="form-control"
              v-model="form.email_fiduciary"
              :readonly="isReadonly"
              :maxlength="100"
              required
            />
            <span class="error d-none"></span>
          </div>
        </div>
        <div class="row"></div>
        <div class="row">
          <div class="col-md-12 my-4 text-end form-group">
            <b-button
              variant="outline-success"
              @click="enableDisableFields"
              class="mx-4 btn-success btn text-white"
              ><i class="fa fa-pencil"> </i> &nbsp; Editar</b-button
            >
            <b-button type="submit" variant="primary" :disabled="isReadonly">
              <i v-if="!isLoading" class="fa fa-save"> </i>
              <i v-else class="fa fa-spinner fa-spin"></i>
              &nbsp;Guardar
            </b-button>
          </div>
        </div>
      </div>
      <!--end content-wrap-container-->

      <div class="content-wrap-container">
        <div class="row">
          <div class="col-md-12">
            <h5 class="text-start mt-3 mb-2">
              Cargar documento
              <i @click="showModal()" class="pointer fa fa-upload"></i>
            </h5>
          </div>

          <div class="col-md-12 mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nombre del documento</th>
                  <th>Fecha de carga</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(type, index) in documentTypes" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td v-if="type.isUploaded">
                    <a
                      @click="downloadDocument(type.id, type.extension)"
                      style="cursor: pointer"
                      class="text-primary"
                    >
                      {{ type.name }}
                    </a>
                  </td>
                  <td v-if="!type.isUploaded">{{ type.name }}</td>
                  <td>
                    {{ type.updated_at }}
                  </td>
                  <td class="text-aling:left !important">
                    <i
                      class="pointer fa fa-trash"
                      v-on:click="deleteDocument(type.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--end content-wrap-container-->
    </form>
    <file-modal-popup
      @refreshDocuments="refreshDocuments"
      :developer_id="developer_id"
      :document_type_id="document_type_id"
    />
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});
import Tabs from "../../components/Project/Tabs.vue";
import {
  getDeveloperData,
  getPersonTypes,
  getDocumentTypes,
} from "../../services/developerData";
import { getProjectInformation } from "../../services/projectAPI";
import { getAllBanks, getAllFiduciary } from "../../services/DropdownListsAPI";
import {
  createEscrow,
  getEscrow,
  updateEscrow,
} from "../../services/EscrowAPI";
import { alertError, alertSuccess } from "../../helpers/alerts";
import FileModalPopup from "./Components/FileModalPopup.vue";
import { getUploadedDocument } from "@/services/DocumentsAPI";
import generalAPI from "@/services/generalAPI";
import moment from "moment";
import SourceTypes from "@/constants/SourceTypes";
import Swal from "sweetalert2";
import { deleteDocumentTypes } from "../../services/DocumentsAPI.js";
export default {
  components: { Tabs, FileModalPopup },
  data() {
    return {
      currencyMask,
      isLoading: false,
      isReadonly: true,
      dev_data: null,
      project_data: null,
      banks: [],
      fiduciary: [],
      documentTypes: [],
      personTypes: [],
      form: {
        id: null, // escrow_id
        project_id: null,
        rnc_escrow: "",
        name_escrow: "",
        bank_id: null,
        account_number_escrow: "",
        name_dev: "",
        identification_number_dev: "",
        phone_number_dev: "",
        cellphone_number_dev: "",
        email_dev: "",
        fiduciary_id: null,
        rnc_fiduciary: "",
        contact_fiduciary: "",
        phone_number_fiduciary: "",
        cellphone_number_fiduciary: "",
        email_fiduciary: "",
      },
      file1: null,
    };
  },
  methods: {
    async getRNCInformation() {
      let { data } = await generalAPI.getDGIIInformation(
        this.form.rnc_escrow.replace(/-/g, "")
      );
      if (data.rnc.length > 0) {
        this.form.name_escrow = data.rnc[0].legal_name;
      }
    },
    async deleteDocument(documentId) {
      await Swal.fire({
        title: "Esta seguro que desea eliminar el archivo?",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          deleteDocumentTypes(this.developer_id, documentId, this.projectId)
            .then(async (data) => {
              await this.refreshDocuments();
              Swal.fire({
                title: "¡Proceso exitoso!",
                text: data,
                icon: "success",
                confirmButtonText: "Listo",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "¡Error!",
                text: "Archivo no existe",
                icon: "error",
                confirmButtonText: "Volver",
              });
            });
        }
      });
    },
    async refreshDocuments() {
      await this.getDocumentType();
    },
    async getUploadedDocument(documents) {
      var data = await getUploadedDocument(
        SourceTypes.Fideicomiso,
        this.projectId
      );

      var fiduciaryData = await getUploadedDocument(
        SourceTypes.Fiduciaria,
        this.projectId
      );

      var developerData = await getUploadedDocument(
        SourceTypes.Desarrollador,
        this.projectId
      );
      data.documents = [
        ...data.documents,
        fiduciaryData.documents,
        developerData.documents,
      ];
      documents.forEach((e) => {
        var document = data.documents.filter((x) => x.document_type_id == e.id);
        if (document.length > 0) {
          e.isUploaded = true;
          e.extension = document[0].extension;
          e.comment = document[0].comment;
          e.answerId = e.answerId == 1 ? "Sí" : "No";
          e.updated_at = moment(document[0].updated_at).format(
            "DD-MM-yyyy hh:mm a"
          );
        } else {
          e.isUploaded = false;
        }
      });
      return documents;
    },
    showModal() {
      this.$root.$emit("bv::show::modal", "LoadFilesModal", "#btnShow");
    },
    async handleSubmit() {
      this.isLoading = true;
      var form = { ...this.form };

      if (form.rnc != null) {
        form.rnc = form.rnc.replace(/-|\s/g, "");
      }

      if (form.phone_number_dev != null) {
        form.phone_number_dev = form.phone_number_dev.replace(/-|\s/g, "");
      }

      if (form.cellphone_number_dev != null) {
        form.cellphone_number_dev = form.cellphone_number_dev.replace(
          /-|\s/g,
          ""
        );
      }

      try {
        this.form.project_id = this.project_data.project.id;

        if (this.form.id !== null) {
          updateEscrow(form)
            .then(() => {
              alertSuccess("Datos actualizados correctamente.");
            })
            .catch(() => {
              alertError(
                "Ha ocurrido un error. Favor actualice e intente de nuevo."
              );
            });
        } else {
          createEscrow(this.form)
            .then(() => {
              alertSuccess("Datos registrados correctamente.");
            })
            .catch(() => {
              alertError(
                "Ha ocurrido un error. Favor actualice e intente de nuevo."
              );
            });
        }
      } catch (e) {
        console.log("e", e);
      }
    },
    resetForm() {
      this.form = {
        rnc_escrow: "",
        name_escrow: "",
        bank_id: null,
        account_number_escrow: "",
        name_dev: "",
        identification_number_dev: "",
        phone_number_dev: "",
        cellphone_number_dev: "",
        email_dev: "",
        fiduciary_id: null,
        rnc_fiduciary: "",
        contact_fiduciary: "",
        phone_number_fiduciary: "",
        cellphone_number_fiduciary: "",
        email_fiduciary: "",
      };
    },
    async getDevData() {
      let data = await getDeveloperData().catch(() => {
        alertError("Error al obtener los datos del desarrollador.");
      });
      this.dev_data = data;
    },
    async getPersonType() {
      let data = await getPersonTypes().catch(() => {
        alertError("Error al obtener los tipos de persona.");
      });
      this.personTypes = data;
    },
    async getDocumentType() {
      let data = await getDocumentTypes().catch(() => {
        alertError("Error al obtener los tipos de documentos.");
      });
      data = await this.getUploadedDocument(data);
      this.documentTypes = data.filter(
        (x) =>
          (x.source_type_category_id == SourceTypes.Fideicomiso ||
            x.source_type_category_id == SourceTypes.Fiduciaria ||
            x.source_type_category_id == SourceTypes.Desarrollador) &&
          x.isUploaded
      );
    },
    enableDisableFields() {
      this.isReadonly = !this.isReadonly;
    },
    openFileModal(typeId) {
      console.log("typeId", typeId);
      this.$bvModal.show("file-modal");
    },
    chunkArray(arr, n) {
      var chunkLength = Math.max(arr.length / n, 1);
      var chunks = [];
      for (var i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length)
          chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
      }
      return chunks;
    },
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  mounted() {
    this.getDocumentType(), this.getDevData(), this.getPersonType();
    getProjectInformation(this.projectId).then((data) => {
      this.project_data = data;
    });
    getAllBanks().then((data) => {
      this.banks = data;
    });
    getAllFiduciary().then((data) => {
      this.fiduciary = data;
    });
    getEscrow(this.projectId).then((data) => {
      if (data.escrow !== null) {
        this.form = data.escrow;
      }
    });
  },
};
</script>

<style scoped>
.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.pointer {
  cursor: pointer;
}
form .form-group {
  text-align: start;
}
table tr th,
table tr td {
  text-align: start;
}
</style>
